<template>
  <div class="createsenior">
    <div class="row justify-content-center text-center">
      <div class="col-10">
        <label for="create_Senior">เลือกยูสที่ต้องการเช็ค</label>
        <v-select
          v-model="showpersen"
          :options="showuser"
          :searchable="true"
          :clearable="true"
        ></v-select>
        <button
          v-if="showpersen"
          class="btn btn-success mx-1 my-2"
          @click="SetPersen()"
          :disabled="loading"
        >
          <span v-if="!loading">เซ็ตเปอร์เซ็น agent</span>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <div v-if="dataset && dataset.length > 0">
          <!-- <button @click="submit_persen()" class="btn btn-warning mx-1 my-2">
            ยืนยัน
          </button> -->

          <table class="table">
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th scope="col">รูป</th>
                <th scope="col">เกม</th>
                <!-- <th scope="col">สถานะ</th> -->
                <th scope="col">
                  ใส่ค่า %
                  <button
                    class="btn btn-primary btn-sm mx-2"
                    data-bs-toggle="modal"
                    data-bs-target="#mockData"
                    @click="getMockData()"
                  >
                    <span v-if="!loading">ดึงค่า mock ข้อมูล</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <button
                    class="btn btn-secondary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#setpersen"
                  >
                    <span v-if="!loading">ใส่ค่าเท่ากัน</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <button
                    class="btn btn-success btn-sm mx-2"
                    @click="get_data_partner()"
                  >
                    <span v-if="!loading">ดึงค่า partner</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>

                  <button
                    class="btn btn-warning btn-sm mx-2"
                    @click="submit_persen_some()"
                  >
                    <span v-if="!loading">ยืนยัน</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <br />
                  <div>เพิ่มค่า - ลดค่า</div>
                  <button class="btn btn-success btn-sm" @click="increment()">
                    เพิ่ม
                  </button>
                  {{ count }}
                  <button class="btn btn-danger btn-sm" @click="decrement()">
                    ลด
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(e, i) in dataset" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  <img :src="e.image_path" alt="" style="max-height: 2rem" />
                </td>
                <td>
                  <span>{{ e.name }}</span>
                </td>
                <!-- <td>
                  <span
                    :class="e.is_game_enable ? 'text-success' : 'text-danger'"
                    >{{ e.is_game_enable ? "เปิด" : "ปิด" }}</span
                  >
                </td> -->
                <td>
                  <input type="number" id="share" v-model="e.share" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      id="mockData"
      ref="mockData"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Mock Data</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <v-select
              v-model="select_mock"
              :options="option_mock"
              :searchable="true"
              :clearable="true"
            ></v-select>

            <table
              class="table table-bordered border-primary text-center"
              v-if="select_mock"
            >
              <thead>
                <tr>
                  <td>ลำดับ</td>
                  <td>รูปเกม</td>
                  <td>ชื่อเกม</td>
                  <td>เปอร์เซ็น</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in select_mock.value" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <img :src="e.img" alt="" style="max-height: 2rem" />
                  </td>
                  <td>{{ e.name }}</td>
                  <td>
                    <span>{{ e.persen }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="submit_mock_data()"
            >
              ยืนยัน
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      id="setpersen"
      ref="setpersen"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Set persen ALL</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <input
              type="number"
              class="form-control form"
              v-model="perseninput"
            />
            <div v-if="perseninput">
              ALL : {{ perseninput }} <br />
              SBO : {{ sbo }} <br />
              LALIKA : {{ lalika }} <br />
              SV388 : {{ sv388 }} <br />
              sboslot : {{ sboslot }} <br />
              funkygame : {{ funkygame }} <br />
              mannaplay : {{ mannaplay }} <br />
              microgaming : {{ microgaming }} <br />
              betsoft : {{ betsoft }} <br />
              playstar : {{ playstar }} <br />
              pushgaming : {{ pushgaming }} <br />
              blueprint : {{ blueprint }} <br />
              imsport : {{ imsport }} <br />
              imesport : {{ imesport }} <br />
              saba : {{ saba }} <br />
              digi : {{ digi }} <br />
              evolution : {{ evolution }} <br />
              3sing : {{ sing3 }}
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="setpersen"
              @click="check()"
            >
              เช็ค
            </button> -->
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="setpersen"
              @click="submit_persen()"
              :disabled="loading"
            >
              <span v-if="!loading">ยืนยัน</span>
              <div class="spinner-border" role="status" v-else>
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let domain = "";
export default {
  data() {
    return {
      showpersen: null,
      showuser: [],
      showdata_persen: null,
      loading: false,
      dataset: [],
      datacheck: [],
      datapartner: null,
      data_mock: null,
      option_mock: [],
      select_mock: null,
      persen: 0,
      perseninput: 0,
      sbo: 0,
      lalika: 0,
      sv388: 0,
      sboslot: 0,
      funkygame: 0,
      mannaplay: 0,
      microgaming: 0,
      betsoft: 0,
      playstar: 0,
      pushgaming: 0,
      blueprint: 0,
      imsport: 0,
      imesport: 0,
      saba: 0,
      digi: 0,
      evolution: 0,
      sing3:0,
      show: true, //
      count: 0,
    };
  },
  watch: {
    perseninput() {
      this.sbo = this.perseninput - parseFloat(4);
      this.lalika = this.perseninput - parseFloat(2);
      this.sv388 = this.perseninput - parseFloat(3);
      this.sboslot = this.perseninput - parseFloat(0.5);
      this.funkygame = this.perseninput - parseFloat(1);
      this.mannaplay = this.perseninput - parseFloat(1);
      this.microgaming = this.perseninput - parseFloat(1);
      this.betsoft = this.perseninput - parseFloat(1);
      this.playstar = this.perseninput - parseFloat(1);
      this.pushgaming = this.perseninput - parseFloat(1);
      this.blueprint = this.perseninput - parseFloat(1.5);
      this.imsport = this.perseninput - parseFloat(2);
      this.imesport = this.perseninput - parseFloat(2);
      this.saba = this.perseninput - parseFloat(4.5);
      this.digi = this.perseninput - parseFloat(3);
      this.evolution = this.perseninput - parseFloat(2);
      this.sing3 = this.perseninput - parseFloat(1);
    },
  },
  methods: {
    increment() {
      this.count++;
      for (let index = 0; index < this.dataset.length; index++) {
        this.dataset[index].share += 1;
      }
    },
    decrement() {
      this.count--;
      for (let index = 0; index < this.dataset.length; index++) {
        this.dataset[index].share -= 1;
      }
    },
    GetAllData() {
      this.loading = true;
      this.axios
        .get(domain + "/api/GetAlluser")
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            result.data.data.forEach((element) => {
              this.showuser.push({
                value: {
                  username: element.username,
                  type: element.type,
                  parent: element.parent,
                },
                label: element.username,
              });
            });
            // this.showuser;
          } else {
            this.$swal({
              title: "เกิดข้อผิดพลาด",
              text: result.data.msg,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    SetPersen() {
      console.log(this.showpersen);
      let body = {
        username: this.showpersen.value.username,
        parent: this.showpersen.value.parent,
        type: this.showpersen.value.type,
      };
      this.loading = true;
      this.axios
        .post(domain + "/api/updatePersen", body)
        .then((result) => {
          this.loading = false;
          this.dataset = [];
          result.data.data.forEach((element) => {
            this.dataset.push({
              image_path: element.image_path,
              name: element.name,
              is_game_enable: element.is_game_enable,
              share: element.share,
              id: element.id,
            });
          });
          result.data.data.forEach((element) => {
            this.datacheck.push({
              image_path: element.image_path,
              name: element.name,
              is_game_enable: element.is_game_enable,
              share: element.share,
              id: element.id,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    check() {
      this.dataset = this.dataset.filter((element) => {
        /* eslint-disable */
        if (element.name.toLowerCase() === "sbo") {
          return (element.share = parseFloat(this.sbo));
        } else if (element.name.toLowerCase() === "lalika") {
          return (element.share = parseFloat(this.lalika));
        } else if (element.name.toLowerCase() === "sv388") {
          return (element.share = parseFloat(this.sv388));
        } else if (element.name.toLowerCase() === "sboslot") {
          return (element.share = parseFloat(this.sboslot));
        } else if (element.name.toLowerCase() === "saba") {
          return (element.share = parseFloat(this.saba));
        } else if (element.name.toLowerCase() === "funkygame") {
          return (element.share = parseFloat(this.funkygame));
        } else if (element.name.toLowerCase() === "mannaplay") {
          return (element.share = parseFloat(this.mannaplay));
        } else if (element.name.toLowerCase() === "microgaming") {
          return (element.share = parseFloat(this.microgaming));
        } else if (element.name.toLowerCase() === "betsoft") {
          return (element.share = parseFloat(this.betsoft));
        } else if (element.name.toLowerCase() === "playstar") {
          return (element.share = parseFloat(this.playstar));
        } else if (element.name.toLowerCase() === "pushgaming") {
          return (element.share = parseFloat(this.pushgaming));
        } else if (element.name.toLowerCase() === "blueprint") {
          return (element.share = parseFloat(this.blueprint));
        } else if (element.name.toLowerCase() === "imsport") {
          return (element.share = parseFloat(this.imsport));
        } else if (element.name.toLowerCase() === "imesport") {
          return (element.share = parseFloat(this.imesport));
        } else if (element.name.toLowerCase() === "saba") {
          return (element.share = parseFloat(this.saba));
        } else if (element.name.toLowerCase() === "digi") {
          return (element.share = parseFloat(this.digi));
        } else if (element.name.toLowerCase() === "evolution") {
          return (element.share = parseFloat(this.evolution));
        }else if (element.name.toLowerCase() === "3sing") {
          return (element.share = parseFloat(this.sing3));
        } else {
          return (element.share = parseFloat(this.perseninput));
        }
      });
    },

    submit_persen() {
      if (this.perseninput) {
        this.check();
        if (this.perseninput && this.sbo && this.lalika && this.sv388) {
          this.loading = true;
          this.axios
            .post(domain + "/api/Conrim_updatePersen", {
              body: {
                data: this.dataset,
                username: this.showpersen.value.username,
                type:
                  this.showpersen.value.type === "Company"
                    ? "senior"
                    : this.showpersen.value.type,
                parent: this.showpersen.value.parent,
              },
            })
            .then((result) => {
              this.loading = false;
              if (result.data.code === 0) {
                this.$swal({
                  title: "success",
                  text: result.data.data,
                  icon: "success",
                });
              } else {
                this.$swal({
                  title: "error",
                  text: result.data.data,
                  icon: "error",
                });
              }
            })
            .catch((err) => {
              this.loading = false;
              return err;
            });
        } else {
          this.$swal({
            title: "เกิดข้อผิดพลาด",
            text: `กรุณาเช็คข้อมูล`,
            icon: "error",
          });
        }
      }
    },
    submit_persen_some() {
      const differentKeyValues = [];
      for (const key in this.dataset) {
        if (this.datacheck[key].share !== this.dataset[key].share) {
          differentKeyValues.push(this.dataset[key]);
        }
      }
      this.loading = true;
      this.axios
        .post(domain + "/api/Conrim_updatePersen", {
          body: {
            data: differentKeyValues,
            username: this.showpersen.value.username,
            type:
              this.showpersen.value.type === "Company"
                ? "senior"
                : this.showpersen.value.type,
            parent: this.showpersen.value.parent,
          },
        })
        .then((result) => {
          this.loading = false;
          if (result.data.code === 0) {
            this.$swal({
              title: "success",
              text: result.data.data,
              icon: "success",
            });
          } else {
            this.$swal({
              title: "error",
              text: result.data.data,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    get_data_partner() {
      this.loading = true;
      this.axios
        .get(
          `${domain}/api/setmodpartner?username=${this.showpersen.value.username}&parent=${this.showpersen.value.parent}&type=${this.showpersen.value.type}`
        )
        .then((result) => {
          if (result.data.code === 0) {
            this.datapartner = result.data.data;
            for (const key1 in this.dataset) {
              for (const key2 in this.datapartner) {
                if (
                  this.dataset[key1].name.toLowerCase() ===
                  this.datapartner[key2].name.toLowerCase()
                ) {
                  this.dataset[key1].share = this.datapartner[key2].share;
                }
              }
            }
            this.$swal({
              title: "success",
              icon: "success",
            });
            // console.log(this.dataset);
          } else {
            this.$swal({
              title: "error",
              icon: "error",
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    getMockData() {
      this.loading = true;
      this.option_mock = [];
      this.axios
        .get(`${domain}/api/mock_persen`)
        .then((result) => {
          result.data.data.forEach((element) => {
            this.option_mock.push({
              value: element.data,
              label: element.name,
            });
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return err;
        });
    },
    submit_mock_data() {
      for (const key1 in this.dataset) {
        for (const key2 in this.select_mock.value) {
          if (
            this.dataset[key1].name.toLowerCase() ===
            this.select_mock.value[key2].name.toLowerCase()
          ) {
            this.dataset[key1].share = this.select_mock.value[key2].persen;
          }
        }
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      domain = location.origin;
      console.log(domain);
      this.GetAllData();
    } else {
      domain = "http://localhost:6900";
      console.log(domain);
      this.GetAllData();
    }
  },
};
</script>
